var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-popover",
    {
      attrs: {
        placement: "bottom",
        width: "200",
        trigger: "click",
        "popper-class": "account_popover",
      },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c("a", {
        staticClass: "iconfont icon-account btn_account",
        attrs: { slot: "reference", href: "javascript:;" },
        on: { click: _vm.logViewMenu },
        slot: "reference",
      }),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div class="pay_layout_header p-r">
        <component :is="isMobile ? 'HeaderM' : 'HeaderPc'">
            <div class="header_info flex-between">
                <div class="top_left flex-center">
                    
                    <slot></slot>

                    <Menus/>
                </div>

                <div class="top_right flex-center">
                    <SaveDesktop v-if="!isMobile && !isPwa"/>

                    <Language />
                    
                    <Account v-if="isLogin" />
                </div>
            </div>
        </component>

    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import HeaderPc from './Pc'
import HeaderM from './M'
import Menus from './Menus'
import Account from './Account'
import Language from './Language'
import SaveDesktop from "@/components/Header/SaveDesktop/index.vue";
import {isPwa} from "@/utils/constants";


export default {
    name: 'LayoutHeader',
    components: {
      SaveDesktop,
        HeaderPc,
        HeaderM,
        Menus,
        Account,
        Language
    },
    props: {
        logo: {
            type: String
        }
    },
    data() {
      return {
        isPwa
      }
    },
    computed: {
        ...mapGetters(['isMobile', 'isLogin'])
    }
}
</script>

<style lang="scss" scoped>
.top_right {
    margin-top: 4px;
}
</style>
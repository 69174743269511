<template>
    <div class="header_account">
        <a href="javascript:;" class="iconfont icon-account btn_account" @click="handleSwitch"></a>

        <van-popup 
            v-model="show" 
            position="right" 
            :style="{ width: '180px', height: '100%', marginTop: '45px' }"
            :overlay-style="{ top: '45px' }"
        >
            <slot></slot>
        </van-popup>

    </div>
</template>

<script>
import { Popup } from 'vant'
import mixin from './mixin'

export default {
    mixins: [mixin],
    components: {
        [Popup.name]: Popup
    },
    methods: {
        handleSwitch() {
            this.show = !this.show
            this.logViewMenu()
        }
    }
}
</script>

<style lang="scss" scoped>
.btn_account {
    font-size: 26px;
    margin-left: 15px;
    padding: 12px 13px;
}
</style>
<template>
    <div>
        <div class="name_text flex-start">
            <span>{{ $sys('header.hello') }},</span>
            <span class="account_name ellipse">{{ nickname }}!</span>
        </div>
        <div class="account_menu" @click="$emit('close')">
            <a href="javascript:;" v-if="!isInGame" @click="handleMenu('logout')">
                <span class="icon icon-logout"></span>
                <span class="name">{{ $sys('account.switchAccount') }}</span>
            </a>
            <a href="javascript:;" v-if="isRole" @click="handleMenu('record')">
                <span class="icon icon-record"></span>
                <span class="name">{{ $sys('account.rechargeRecord') }}</span>
            </a>
            <!-- <a href="javascript:;" @click="handleMenu('notice')">
                <span class="iconfont icon-notice"></span>
                <span class="name">{{ $xy('user.noticy') }}</span>
            </a> -->
            <a href="javascript:;" @click="handleMenu('help')">
                <span class="icon icon-help"></span>
                <span class="name">{{ $sys('account.help') }}</span>
            </a>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import { logClickInmenu, logClickRecord, logClickLogout } from '@/utils/log';

export default {
    computed: {
        ...mapState('user', ['userInfo']),
        ...mapGetters(['isInGame', 'isRole', 'nickname'])
    },
    methods: {
        ...mapMutations('user', ['SET_IS_POP_HELP']),
        ...mapActions('user', ['logout']),
        handleMenu(name) {
            if(name === 'logout') {
                this.logout()
                logClickLogout()
            }else if(name === 'record') {
                this.$router.push('record')
                logClickRecord()
            }else {
                if(name === 'help') this.SET_IS_POP_HELP(true)

                name && logClickInmenu(name)
            }            
        }
    }
}
</script>

<style lang="scss" scoped>
.name_text {
    font-size: 14px;
    padding: 10px;
    padding-top: 20px;
    .account_name {
        display: inline-block;
        max-width: 90px;
        margin-left: 3px;
    }
}
.account_menu {
    >a {
        // display: block;
        height: 50px;
        line-height: 50px;
        padding-left: 15px;
        font-size: 14px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        &:hover {
            background-color: #f5f2f2;
        }
        .icon {
            margin-right: 10px;
        }
        .name {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
    }

    .icon-notice,
    .icon-help {
        font-size: 24px;
        margin-right: 8px;
    }
}
</style>
<template>
    <el-popover placement="bottom" width="200" trigger="click" popper-class="account_popover" v-model="show">
        
        <a href="javascript:;"  slot="reference" class="iconfont icon-account btn_account" @click="logViewMenu"></a>

        <slot></slot>

    </el-popover>
</template>

<script>
import { Popover } from 'element-ui'
import mixin from './mixin'

export default {
    mixins: [mixin],
    components: {
        [Popover.name]: Popover
    }
}
</script>

<style lang="scss">
.btn_account {
    font-size: 16px;
    margin-left: 15px;
    margin-top: 8px;
    padding: 0;
    padding: 8px;
    border-radius: 50%;
    background-color: #F2F3F5;
}

.account_popover {
    padding: 0;
}

.account_info {
    padding: 10px;
    padding-top: 20px;
    .account_name {
        display: inline-block;
        max-width: 120px;
        margin-bottom: -5px;
    }
}

</style>
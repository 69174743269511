<template>
  <div class="layout_content">
      <slot></slot>
  </div>
</template>

<script>


export default {
}
</script>

<style lang="scss" scoped>
</style>
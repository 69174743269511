var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "header_account" },
    [
      _c("a", {
        staticClass: "iconfont icon-account btn_account",
        attrs: { href: "javascript:;" },
        on: { click: _vm.handleSwitch },
      }),
      _c(
        "van-popup",
        {
          style: { width: "180px", height: "100%", marginTop: "45px" },
          attrs: { position: "right", "overlay-style": { top: "45px" } },
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [_vm._t("default")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
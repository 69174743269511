<template>
    <component :is="isMobile ? 'AccountM' : 'AccountPc'" v-model="visibleMenu">
        <SubMenu @close="visibleMenu = false" />
    </component>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex'
import AccountPc from './Pc'
import AccountM from './M'
import SubMenu from './SubMenu'

export default {
    components: {
        AccountPc,
        AccountM,
        SubMenu
    },
    computed: {
        ...mapGetters(['isMobile']),
        ...mapState('user', ['isPopAccount']),
        visibleMenu: {
            get() {
                return this.isPopAccount
            },
            set(val) {
                this.SET_IS_POP_ACCOUNT(val)
            }
        }
    },
    methods: {
        ...mapMutations('user', ['SET_IS_POP_ACCOUNT']),
        handleClose() {
            this.visibleMenu = false
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
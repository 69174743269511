<!-- 
 * @FileDescription: 
 * @Author: yangjinhong
 * @Date: 2024/10/28 15:35
 * @LastEditors: yangjinhong
 * @LastEditTime: 2024/10/28
-->

<template>
  <div class="save_desktop">
    <el-button type='text' class='btn_save' @click="saveDesktop">
      <div class="flex-center">
        <span class='icon icon-save'></span>
        <span class='lang_name'>{{ $t("sys.btn.saveDesktop") }}</span>
      </div>
    </el-button>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { Button } from 'element-ui'
export default {
  name: "SaveDesktop",
  components:{
    [Button.name]: Button
  },
  data() {
    return {}
  },
  methods: {
    ...mapActions('user', ['saveDesktop']),
  }
}
</script>

<style scoped lang="scss">

</style>
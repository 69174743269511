<template>
    <ul class="header_menu flex-center">
        <li>
            <a 
                href="javascript:;" 
                class="nav_first" 
                :class="{ 'active': $route.path.indexOf('/index') > 0 && isRole }" 
                @click="handleMenu('home')">
                {{ $sys('header.gameRecharge') }}
            </a>
        </li>
        <li hidden>
            <a 
                href="javascript:;" 
                class="nav_first" 
                @click="handleMenu('vip')">
                会员中心
            </a>
        </li>
    </ul>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { logVipCenter } from '@/utils/log';

export default {
    computed: {
        ...mapGetters(['isRole'])
    },
    methods: {
        ...mapActions('user', ['logout']),
        handleMenu(name) {
            if(!this.isRole) {
                this.logout()
            }else {
                if(name === 'home') this.$router.push('index')
                
                if(name === 'vip') logVipCenter()
            }
        }
    }
}
</script>

<style lang="scss">

</style>
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ul", { staticClass: "header_menu flex-center" }, [
    _c("li", [
      _c(
        "a",
        {
          staticClass: "nav_first",
          class: {
            active: _vm.$route.path.indexOf("/index") > 0 && _vm.isRole,
          },
          attrs: { href: "javascript:;" },
          on: {
            click: function ($event) {
              return _vm.handleMenu("home")
            },
          },
        },
        [_vm._v(" " + _vm._s(_vm.$sys("header.gameRecharge")) + " ")]
      ),
    ]),
    _c("li", { attrs: { hidden: "" } }, [
      _c(
        "a",
        {
          staticClass: "nav_first",
          attrs: { href: "javascript:;" },
          on: {
            click: function ($event) {
              return _vm.handleMenu("vip")
            },
          },
        },
        [_vm._v(" 会员中心 ")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
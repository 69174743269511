var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pay_layout_footer_height" }, [
    _c(
      "div",
      { staticClass: "pay_layout_footer", class: "p-r" },
      [_vm._t("default")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
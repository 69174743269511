var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "save_desktop" },
    [
      _c(
        "el-button",
        {
          staticClass: "btn_save",
          attrs: { type: "text" },
          on: { click: _vm.saveDesktop },
        },
        [
          _c("div", { staticClass: "flex-center" }, [
            _c("span", { staticClass: "icon icon-save" }),
            _c("span", { staticClass: "lang_name" }, [
              _vm._v(_vm._s(_vm.$t("sys.btn.saveDesktop"))),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { logViewMenu } from "@/utils/log"

export default {
    props: {
        value: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        show: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            }
        }
    },
    methods: {
        logViewMenu
    }
}
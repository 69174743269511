var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pay_layout_header p-r" },
    [
      _c(_vm.isMobile ? "HeaderM" : "HeaderPc", { tag: "component" }, [
        _c("div", { staticClass: "header_info flex-between" }, [
          _c(
            "div",
            { staticClass: "top_left flex-center" },
            [_vm._t("default"), _c("Menus")],
            2
          ),
          _c(
            "div",
            { staticClass: "top_right flex-center" },
            [
              !_vm.isMobile && !_vm.isPwa ? _c("SaveDesktop") : _vm._e(),
              _c("Language"),
              _vm.isLogin ? _c("Account") : _vm._e(),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="pay_layout_footer_height">
      <div class="pay_layout_footer" :class="'p-r'">
          <slot></slot>
      </div>
  </div>
</template>

<script>
export default {
    name: 'LayoutFooter',
}
</script>

<style lang="scss" scoped>

</style>
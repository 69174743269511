<template>
    <div>
        <slot></slot>
    </div>
</template>

<script>

export default {

}
</script>

<style lang="scss">
.device-m {
    .pay_layout_header {
        min-width: 0;
        height: 90px;
        background-color: #fff;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 100;
        -webkit-box-shadow: 0 2px 3px 2px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0 2px 3px 2px rgba(0, 0, 0, 0.1);
        box-shadow: 0 2px 3px 2px rgba(0, 0, 0, 0.1);
    }
    .header_info {
        height: 90px;
        padding: 0 20px;
        .logo {
            height: 60px;
        }
        .title {
            max-width: 220px;
            font-size: 24px;
            font-weight: bold;
            color: #222222;
            margin-left: 20px;
        }
    }

    .header_menu {
        height: 60px;
        font-size: 30px;
        align-items: flex-start;
        margin-left: 20px;
        color: #222222;
        >li {
            height: 100%;
            position: relative;
            padding: 0 10px;
        }

        .nav_first {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            // @mixin active {
            //     &::after {
            //         content: '';
            //         width: 72%;
            //         height: 2px;
            //         background: #FA894D;
            //         position: absolute;
            //         bottom: 2px;
            //     }
            // }
            // &.active {
            //     @include active;
            // }
            // &:hover {
            //     @include active;
            // }
        }
    }





    .btn_lang {
        margin-left: 20px;
        height: 50px;
        color: #000;
        font-size: 24px;
        background-color: #F2F3F5;
        border-radius: 25px;
        padding: 0 20px;
        .icon-lang {
            font-size: 30px;
            margin-right: 6px;
        }
        &:hover, &:focus {
            color: #000;
        }
    }
    
    
}
</style>
<template>
    <div class="page_content">
        <slot></slot>
    </div>
</template>

<script>

export default {

}
</script>

<style lang="scss">
@import '@/style/var.scss';

.device-pc {
    .pay_layout_header {
        min-width: $pc-content-width;
        height: 80px;
        background-color: #fff;
        position: sticky;
        top: 0;
        left: 0;
        right: 0;
        z-index: 100;
        -webkit-box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.1);
        -moz-box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.1);
        box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.1);
    }
    .header_info {
        height: 100%;
        margin: 0 auto;
        padding: 0 20px;
        .logo {
            height: 65px;
        }
        .title {
            font-size: 24px;
            font-weight: bold;
            color: #222222;
            margin-left: 30px;
        }

        .save_desktop{
          cursor: pointer;
          .btn_save {
            display: block;
            height: 32px;
            line-height: 32px;
            color: #000;
            font-size: 16px;
            background-color: #F2F3F5;
            border-radius: 16px;
            padding: 0 15px;
            .icon-save {
              margin-right: 6px;
            }
            &:hover, &:focus {
              color: #000;
              background-color: #F2F3F5;
            }
          }
        }
    }


    .header_menu {
        height: 60px;
        font-size: 18px;
        align-items: flex-start;
        margin-left: 30px;
        color: #222222;
        >li {
            height: 100%;
            padding: 0 20px;
            position: relative;
        }


        .nav_first {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            @mixin active {
                &::after {
                    content: '';
                    width: 72%;
                    height: 2px;
                    background: #FA894D;
                    position: absolute;
                    bottom: 12px;
                }
            }
            &.active {
                @include active;
            }
            &:hover {
                @include active;
            }
        }
    }
    
    .btn_lang {
        margin-left: 15px;
        display: block;
        height: 32px;
        color: #000;
        font-size: 16px;
        background-color: #F2F3F5;
        border-radius: 16px;
        padding: 0 15px;
        .icon-lang {
            font-size: 20px;
            margin-right: 6px;
        }
        &:hover, &:focus {
            color: #000;
            background-color: #F2F3F5;
        }
    }
}



</style>
<template>
  <div v-if='countryLang' class='header_lang'>

    <el-popover placement='bottom' width='200' trigger='click' popper-class='lang_popover'>

      <el-form>
        <el-form-item :label="$sys('form.label.region')">
          <el-select v-model='countryVal' popper-class='lang_select'>
            <el-option
              v-for='item in countryLang'
              :key='item.value'
              :label='item.country + `（${item.value}）`'
              :value='item.value'>
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item :label="$sys('form.label.lang')">
          <el-select v-model='langVal' popper-class='lang_select'>
            <el-option
              v-for='item in langList'
              :key='item.value'
              :label='formatLangsShow(item.label)'
              :value='item.value'>
            </el-option>
          </el-select>

        </el-form-item>

      </el-form>

      <el-button type='text' slot='reference' class='btn_lang' @click='handleLang'>
        <div class="flex-center">
          <span class='iconfont icon-lang'></span>
          <span class='lang_name'>{{ langKey }}</span>
        </div>
      </el-button>

    </el-popover>

  </div>

</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import { Popover, Button, Select, Option, Form, FormItem } from 'element-ui'
import { logChangeLanguage } from '@/utils/log'
import { formatLangsShow } from '@/utils'

export default {
  components: {
    [Popover.name]: Popover,
    [Button.name]: Button,
    [Select.name]: Select,
    [Option.name]: Option,
    [Form.name]: Form,
    [FormItem.name]: FormItem
  },
  computed: {
    ...mapState('game', ['country', 'lang', 'countryLang']),
    langList() {
      return this.countryLang.find(item => item.value === this.country)?.langs || []
    },
    langKey() {
      if (this.countryVal) return this.countryVal
      return this.langList.find(item => item.value === this.langVal)?.key
    },
    countryVal: {
      get() {
        return this.country
      },
      set(country) {
        this.chooseCountry({ country })
      }
    },
    langVal: {
      get() {
        return this.lang
      },
      set(val) {
        this.chooseLang({ lang: val })
      }
    }
  },
  methods: {
    ...mapActions('game', ['chooseLang', 'chooseCountry']),
    ...mapMutations('user', ['SET_IS_POP_ACCOUNT']),
    formatLangsShow,
    handleLang() {
      this.SET_IS_POP_ACCOUNT(false)

      logChangeLanguage()
    }
  }
}
</script>

<style lang='scss'>


.lang_popover {
  $bgcolor: rgba(0, 0, 0, .8);
  $txtColor: #fff;

  background-color: $bgcolor;
  border: none;

  .popper__arrow {
    border-bottom-color: $bgcolor !important;

    &::after {
      border-bottom-color: $bgcolor !important;
      margin-left: -5px !important;
    }
  }

  .el-form-item__label {
    color: $txtColor;
  }

  .el-input__inner {
    //background-color: #4EB7CD;
    //color: $txtColor;
    background-color: #ffffff;
    color: #000000;
    border-color: transparent;
  }

  .el-input.is-focus .el-input__inner {
    border-color: transparent !important;
  }

  .el-select .el-input__inner:focus {
    border-color: transparent !important;
  }

  .el-select__caret {
    //color: $txtColor !important;
    color:#000000;
  }

}

.lang_select {
  .el-select-dropdown__item.selected {
    color: #4EB7CD;
  }
}


</style>
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.isMobile ? "AccountM" : "AccountPc",
    {
      tag: "component",
      model: {
        value: _vm.visibleMenu,
        callback: function ($$v) {
          _vm.visibleMenu = $$v
        },
        expression: "visibleMenu",
      },
    },
    [
      _c("SubMenu", {
        on: {
          close: function ($event) {
            _vm.visibleMenu = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
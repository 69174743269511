var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "name_text flex-start" }, [
      _c("span", [_vm._v(_vm._s(_vm.$sys("header.hello")) + ",")]),
      _c("span", { staticClass: "account_name ellipse" }, [
        _vm._v(_vm._s(_vm.nickname) + "!"),
      ]),
    ]),
    _c(
      "div",
      {
        staticClass: "account_menu",
        on: {
          click: function ($event) {
            return _vm.$emit("close")
          },
        },
      },
      [
        !_vm.isInGame
          ? _c(
              "a",
              {
                attrs: { href: "javascript:;" },
                on: {
                  click: function ($event) {
                    return _vm.handleMenu("logout")
                  },
                },
              },
              [
                _c("span", { staticClass: "icon icon-logout" }),
                _c("span", { staticClass: "name" }, [
                  _vm._v(_vm._s(_vm.$sys("account.switchAccount"))),
                ]),
              ]
            )
          : _vm._e(),
        _vm.isRole
          ? _c(
              "a",
              {
                attrs: { href: "javascript:;" },
                on: {
                  click: function ($event) {
                    return _vm.handleMenu("record")
                  },
                },
              },
              [
                _c("span", { staticClass: "icon icon-record" }),
                _c("span", { staticClass: "name" }, [
                  _vm._v(_vm._s(_vm.$sys("account.rechargeRecord"))),
                ]),
              ]
            )
          : _vm._e(),
        _c(
          "a",
          {
            attrs: { href: "javascript:;" },
            on: {
              click: function ($event) {
                return _vm.handleMenu("help")
              },
            },
          },
          [
            _c("span", { staticClass: "icon icon-help" }),
            _c("span", { staticClass: "name" }, [
              _vm._v(_vm._s(_vm.$sys("account.help"))),
            ]),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }